import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import clsx from 'clsx';
import 'keen-slider/keen-slider.min.css';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { SectionHeader } from 'components/shared/section-header/section-header';
import partner1 from 'assets/images/partners/partner-1.png';
import partner2 from 'assets/images/partners/partner-2.png';
import partner3 from 'assets/images/partners/partner-3.png';
import partner4 from 'assets/images/partners/partner-4.png';
import partner5 from 'assets/images/partners/partner-5.png';
import partner6 from 'assets/images/partners/partner-6.png';
import partner7 from 'assets/images/partners/partner-7.png';
import partner8 from 'assets/images/partners/partner-8.png';
import partner9 from 'assets/images/partners/partner-9.png';
import partner10 from 'assets/images/partners/partner-10.png';
import partner11 from 'assets/images/partners/partner-11.png';
import partner12 from 'assets/images/partners/partner-12.png';
import partner13 from 'assets/images/partners/partner-13.jpg';
import partner14 from 'assets/images/partners/partner-14.svg';
import partner15 from 'assets/images/partners/partner-15.png';
import { usePageType } from 'hooks/usePageType/usePageType';

import styles from './logo-carousel.module.scss';
import { PER_PAGE } from './logo-carousel.utils';

export const LogoCarousel = () => {
  const { isBooths } = usePageType();
  const { formatMessage } = useLocale();
  const [pause, setPause] = useState(false);
  const timer: { current: NodeJS.Timeout | null } = useRef(null);
  const [activePage, setActivePage] = useState(0);

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: PER_PAGE.mobile,
    spacing: 15,
    initial: 0,
    loop: true,
    duration: 1000,
    centered: true,
    mode: 'snap',
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    },
    slideChanged(s) {
      setActivePage(s.details().relativeSlide);
    },
    breakpoints: {
      '(min-width: 768px)': {
        slidesPerView: PER_PAGE.tablet,
      },
      '(min-width: 1200px)': {
        slidesPerView: isBooths ? PER_PAGE.desktop : 3,
        loop: isBooths,
        centered: false,
      },
    },
  });

  const numOfPages = useRef<number>(0);

  const [pages, setPages] = useState<number[]>([]);

  useEffect(() => {
    const ref = sliderRef.current;
    const pauseSlider = (targetState: boolean) => () => {
      setPause(targetState);
    };

    if (sliderRef && ref) {
      ref.addEventListener('mouseover', pauseSlider(true));
      ref.addEventListener('mouseout', pauseSlider(false));
    }

    return () => {
      if (sliderRef && ref) {
        ref.removeEventListener('mouseover', pauseSlider(true));
        ref.removeEventListener('mouseout', pauseSlider(false));
      }
    };
  }, [sliderRef]);

  useEffect(() => {
    if (slider) {
      // NOTE: don't need to detect viewport size and differentiate PER_PAGE.desktop / PER_PAGE.tablet / PER_PAGE.mobile because we have controls which allow user to change the page only on desktop
      numOfPages.current = Math.ceil(slider.details().size / PER_PAGE.desktop);

      setPages(Array.apply(null, Array(numOfPages.current)).map((_, i) => (i === 0 ? 0 : PER_PAGE.desktop * i)));
    }
  }, [slider]);

  useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, 7000);
    return () => {
      clearInterval(timer.current as NodeJS.Timeout);
    };
  }, [pause, slider]);

  return (
    <div className={styles.root}>
      <SectionHeader
        title={
          isBooths
            ? formatMessage({
                id: AppMessages['partners.booths.heading'],
              })
            : formatMessage({
                id: AppMessages['partners.apps.heading'],
              })
        }
      />

      <div ref={sliderRef} className="keen-slider" dir="ltr">
        {isBooths ? (
          <>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner1}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner2}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner3}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner4}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner5}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner6}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner7}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner8}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner9}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
          </>
        ) : (
          <>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner10}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                height={76}
                width={134}
                src={partner13}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner14}
                height={150}
                width={200}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner12}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
            <div className={clsx('keen-slider__slide', styles.slide)}>
              <Image
                src={partner15}
                alt={formatMessage({
                  id: AppMessages['partners.alt'],
                })}
              />
            </div>
          </>
        )}
      </div>
      {slider && (
        <div className={styles.dots}>
          {pages &&
            pages.map((page, i) => {
              return (
                <button
                  key={i}
                  onClick={() => {
                    slider.moveToSlideRelative(page);
                  }}
                  className={clsx(styles.dot, {
                    [styles.active]: activePage >= page && (activePage < pages[i + 1] || i + 1 === numOfPages.current),
                  })}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};
