import Image from 'next/image';
import clsx from 'clsx';

import { LINKS } from 'assets/data/links';
import { SOCIAL_MEDIA } from 'assets/data/social-media';
import { MENU_LINKS } from 'assets/data/menu-links';
import TwitterIcon from 'assets/images/twitter-icon.svg';
import InstagramIcon from 'assets/images/instagram-icon.svg';
import HRDFIcon from 'assets/images/hrdf-icon.svg';
import TojjarFooterIcon from 'assets/images/tojjar-footer-icon.svg';
import nineTenIcon from 'assets/images/910-icon.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { usePageType } from 'hooks/usePageType/usePageType';
import { AppMessages } from 'i18n/messages';
import certificateStamp from 'assets/images/footer/stamp-certificate.png';

import styles from './footer.module.scss';

export const Footer = ({ className }: { className?: string }) => {
  const { formatMessage } = useLocale();
  const { isBooths, isApps } = usePageType();

  const isActiveClass = (linkName: string) => {
    return (linkName === 'Booths' && isBooths) || (linkName === 'Apps' && isApps);
  };

  return (
    <div className={clsx(styles.footer, className && className)}>
      <div className={styles.footerWrapper}>
        <div className={styles.firstBar}>
          <div className={styles.logosWrapper}>
            <a className={styles.linkWithLogo} href={process.env.NEXT_PUBLIC_TOJJAR_URL}>
              <Image priority src={TojjarFooterIcon} width={54.5} height={40} alt="tojjar" />
            </a>
            <div className={styles.divider} />
            <a className={styles.linkWithLogo} href={process.env.NEXT_PUBLIC_N910TH_URL}>
              <Image priority src={nineTenIcon} width={55.5} height={30} alt="910ths" />
            </a>
          </div>
          <div className={styles.linksWrapper}>
            {MENU_LINKS.map((link) => (
              <a
                key={link.name}
                href={link.url}
                className={clsx(styles.link, isActiveClass(link.name) && styles.activeLink)}
              >
                {formatMessage({
                  id: AppMessages[link.translationKey],
                })}
              </a>
            ))}
          </div>
        </div>
        <div className={styles.secondBar}>
          <div className={styles.initiative}>
            {formatMessage({
              id: AppMessages['footer.initiativeBy'],
            })}
            <Image priority src={HRDFIcon} width={65} height={30} alt="910ths" />
          </div>
          <div className={styles.links}>
            {LINKS.map((link) => (
              <a key={link.name} target="_blank" rel="noreferrer" className={styles.href} href={link.url}>
                {formatMessage({
                  id: AppMessages[link.translationKey],
                })}
              </a>
            ))}
          </div>
          <div className={styles.socials}>
            <a href={SOCIAL_MEDIA.instagram} target="_blank" rel="noreferrer" className={styles.socialLink}>
              <Image priority src={InstagramIcon} width={16} height={16} alt="instagram" />
            </a>
            <a href={SOCIAL_MEDIA.twitter} target="_blank" rel="noreferrer" className={styles.socialLink}>
              <Image priority src={TwitterIcon} width={14} height={14} alt="twitter" />
            </a>
          </div>
        </div>
        <div className={styles.stampWrapper}>
          <a href="http://raqmi.dga.gov.sa/platforms/DigitalStamp/ShowCertificate/254">
            <Image src={certificateStamp} alt="Digital Stamp" />
          </a>
        </div>
      </div>
    </div>
  );
};
